import React from 'react';
import { SideNav } from '@hhs/ui/src/common/SideNav';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { honeypot } from 'src/tools/honeypot';
import { registerAnalytics } from 'src/tools/analytics';
import { attachStylesheet } from '../../styles/stylesheet';
import { config } from '@abyss/web/tools/config';
import { init as initApm } from '@elastic/apm-rum';

export const App = () => {
  
  registerAnalytics();
  honeypot();
  attachStylesheet();

  initApm({
    serviceName: config('ELASTIC_APM_SERVICE_NAME'),
    serverUrl: config('ELASTIC_APM_SERVER_URL'),    
    environment: config('ELASTIC_APM_ENV'), 
    pageLoadSampled: true,
    logLevel: 'trace',
    active: true,
    apmRequest: true,
  });
  
  return (
    <React.Fragment>
      <Header />
      <SideNav />
      <Content />
      <Footer />
    </React.Fragment>
  );
};
